<template>
<div>
  <v-card flat>
    <v-container :fluid="true">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-switch v-model="showAllRides" :label="showAllRides ? 'Showing full rides' : 'Showing only rides that are not already full'" @change="load()"></v-switch>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="showFilters">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-text-field v-model="churchNameFilter" label="Filter by Church Name" outlined @input="load"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field v-model="churchDenominationFilter" label="Filter by Denomination" outlined @input="load"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select v-model="pickupLocationFilter" label="Filter by Pickup Location" :items="pickupLocationOptions" outlined @change="load" clearable></v-select>
        </v-col>
      </v-row>
      </v-container>
    <v-data-table
      :loading="loading"
      :items="availableRides"
      :headers="headers"
      :server-items-length="totalCount"
      :single-select="true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 30, 40, 50] }"
      item-key="_id"
      show-select
      v-model="selected"
      @update:options="load"
    >
      <template v-slot:item.departureDate="{ item }">
        {{ item.departureDate | stringFormatDate }}
      </template>
      <template v-slot:item.recurringWeekly="{ item }">
        {{ item.recurringWeekly ? 'Yes' : 'No' }}
      </template>
    </v-data-table>
  </v-card>

  <div style="padding: 10px; max-width: 650px;">
    <v-row dense>
      <v-col cols="auto">
        <v-btn size="medium" :disabled="selected.length == 0" @click="submitHandler(selected)">{{ submitButtonLabel }}</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-dialog v-model="showDetailsDialog" max-width="650px" :scrollable="false">
          <template v-slot:activator="{ on }">
            <v-btn v-if="showDetailButton" size="medium" :disabled="selected.length == 0" v-on="on">Show Details</v-btn>
          </template>
          <v-card>
        <div v-if="selected[0]">
        <v-card-title>
        <v-row align="center">
          <v-col cols="auto">
            <span md="auto">
              Ride Details
            </span>
          </v-col>
          <v-col cols="auto" v-if="selected[0].recurringWeekly" >
            <v-chip color="blue">
              Recurring Weekly
            </v-chip>
          </v-col>
          <v-col cols="auto" v-if="selected[0].seatsAvailable > 0" >
            <v-chip color="green">
              {{ selected[0].seatsAvailable }} Seats Left
            </v-chip>
          </v-col>
        </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
        <label v-if="!isDriver" class="text-subtitle-1"><strong>Driver's Email:</strong> {{ selected[0].email }} </label>
        <v-row>
          <v-col md="6">
            <v-card>
              <v-card-subtitle class="text-h5" style="font-weight: bold;"><i class="fas fa-car"></i> Departure Info</v-card-subtitle>

              <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Departure Date and Time</v-list-item-title>
                <v-list-item-subtitle>{{ selected[0].departureDate | stringFormatDate }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Pickup Location</v-list-item-title>
                <v-list-item-subtitle>{{ selected[0].pickupLocation }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            </v-card>
          </v-col>
          <v-col md="6">
            <v-card>
              <v-card-subtitle class="text-h5" style="font-weight: bold;"><i class="fas fa-church"></i> Church Info</v-card-subtitle>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Church Name</v-list-item-title>
                <v-list-item-subtitle>{{ selected[0].churchName }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

           <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Church Denomination</v-list-item-title>
                <v-list-item-subtitle>{{ selected[0].churchDenomination }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="selected[0].carDescription" align="center">
          <v-col cols="auto">
            <span class="text-h6"><strong>Car Description: </strong></span>
          </v-col>
          <v-col cols="auto">
            <span>{{ selected[0].carDescription }}</span>
          </v-col>
        </v-row>
        <v-row v-if="selected[0].recurringWeekly" align="center">
          <v-col cols="auto">
            <span class="text-h6"><strong>End Date: </strong></span>
          </v-col>
          <v-col cols="auto">
            <span class="text">{{ new Date(selected[0].dateRange.end).toDateString() }} (the date when the ride will stop recurring weekly)</span>
          </v-col>
        </v-row>
          <v-row v-if="isDriver" align="center">
            <v-col cols="auto">
              <span class="text-h6"><strong>Registered Riders ({{ selected[0].registeredRiders.length }}): </strong></span>
            </v-col>
            <v-col cols="auto" v-if="selected[0].registeredRiders.length == 0">
              <span>There are currently no drivers registered for this ride</span>
            </v-col>
            <v-col cols="auto" v-else>
              <v-list>
                <v-list-item v-for="rider in selected[0].registeredRiders" :key="rider.name">
                  {{ rider.email }}
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        </div>
        <v-card-actions>
          <v-btn text @click="showDetailsDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
      </v-col>
    </v-row>
  </div>
</div>
</template>

<script>
import { ref, computed, onBeforeMount, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../helpers/formatters'

export default {
  props: {
    tableConfig: Object
  },
  setup (props, { root, refs, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const availableRides = ref([])
    const options = ref({ })
    const totalCount = ref(0)
    const selected = ref([])
    const loading = ref(false)
    const { submitButtonLabel, isDriver, showFullRides, showFilters, showDetailButton } = props.tableConfig
    const showAllRides = ref(showFullRides)
    const churchNameFilter = ref('')
    const churchDenominationFilter = ref('')
    const pickupLocationFilter = ref('')
    const showDetailsDialog = ref(false)
    const showEditDialog = ref(false)

    const isFiltered = computed(() => churchNameFilter.value !== '' || churchDenominationFilter.value !== '' || pickupLocationFilter.value !== '')

    const denominationOptions = [
      { text: 'Presbyterian', value: 'Presbyterian' },
      { text: 'Baptist', value: 'Baptist' },
      { text: 'Pentecostal', value: 'Pentecostal' },
      { text: 'Methodist', value: 'Methodist' },
      { text: 'Episcopal', value: 'Episcopal' },
      { text: 'Catholic', value: 'Catholic' },
      { text: 'Other', value: 'Other' }
    ]

    const service = root.$feathers.service('ride-share/available-rides')
    const pickupLocationOptions = [
      { text: 'Carter Circle', value: 'Carter Circle' },
      { text: 'Mac Circle', value: 'Mac Circle' }
    ]

    const headers = computed(() => {
      let arr = [
        { text: 'Driver Email', name: 'email', value: 'email' },
        { text: 'Church', name: 'churchName', value: 'churchName' },
        { text: 'Denomination', value: 'churchDenomination' },
        { text: 'Departure Date and Time', value: 'departureDate' },
        { text: 'Pickup Location', value: 'pickupLocation' },
        { text: 'Seats Available', value: 'seatsAvailable' },
        { text: 'Recurring Weekly', value: 'recurringWeekly' }
      ]
      return arr
    })

    onBeforeMount(() => {
      root.$feathers.service('ride-share/available-rides').on('created', load)
      root.$feathers.service('ride-share/available-rides').on('updated', load)
      root.$feathers.service('ride-share/available-rides').on('patched', load)
      root.$feathers.service('ride-share/available-rides').on('removed', load)
    })

    onMounted(() => {
      load()
    })

    function load (newOptions) {
      loading.value = true
      if (newOptions) options.value = newOptions
      const { sortBy, sortDesc, page, itemsPerPage } = options.value
      let query = {
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {}
      }

      // Sorts
      if (Array.isArray(sortBy) && sortBy.length > 0) {
        for (let i = 0; i < sortBy.length; i++) {
          query.$sort[sortBy[i]] = sortDesc[i] ? -1 : 1
        }
      } else {
        query.$sort.departureDate = 1
      }

      const email = user.value.email
      if (props.tableConfig.isDriver) {
        query.email = email
      } else if (props.tableConfig.isRider) {
        // Check if the user is a rider
        query['registeredRiders.email'] = {
          $in: email
        }
      } else {
        // All Available Rides - do not show the ride if you are the driver
        query.email = {
          $nin: email
        }
        query['registeredRiders.email'] = {
          $nin: email
        }
      }

      // Filters
      if (churchNameFilter.value !== '') {
        query.churchName = { $regex: churchNameFilter.value, $options: 'i' }
      }

      if (churchDenominationFilter.value !== '') {
        query.churchDenomination = { $regex: churchDenominationFilter.value, $options: 'i' }
      }

      if (pickupLocationFilter.value !== '') {
        query.pickupLocation = pickupLocationFilter.value
      }

      // Only get rides that are active (today is not after endDate)
      query['dateRange.end'] = {
        $gt: new Date()
      }

      service.find({ query }).catch((err) => {
        alert('Error loading available rides: ' + err)
        loading.value = false
      }).then(({ data, total }) => {
        totalCount.value = total
        if (total === 0 && !isFiltered.value) {
          emit('hasNoData')
        }

        // Increment departure date for recurring rides
        for (let i in data) {
          let da = data[i]
          let date = new Date(da.departureDate)
          let endDate = new Date(da.dateRange.end)
          if (date < new Date() && da.recurringWeekly) {
            // Departure date is in the past
            date.setDate(date.getDate() + 7)
            // console.log(date)
            if (date > endDate) {
              endDate.setDate(new Date() - 1)
              data.splice(i, 1)
            }

            service.patch(da._id, { departureDate: date, dateRange: { end: endDate, start: da.start } }).then(() => load())
            // console.log('UPDATED DEPARTURE DATE for ' + date.toDateString)
          }
        }

        if (!showAllRides.value) data = data.filter(d => d.seatsAvailable > 0)
        availableRides.value = data
        loading.value = false
      })
    }

    function editRide () {
      if (refs.editForm.validate()) {
        let data = selected.value[0]

        root.$feathers.service('ride-share/available-rides').update(data._id, data)
          .catch((err) => {
            let snackbar = { active: true, color: 'error', timeout: 6000, text: 'There was an error editing your ride. ' + err }
            root.$store.dispatch('main/snackbar', snackbar)
          }).then(() => {
            showEditDialog.value = false
            let snackbar = { active: true, color: 'success', timeout: 6000, text: 'Changes saved successfully' }
            root.$store.dispatch('main/snackbar', snackbar)
            load()
          })
      }
    }

    function submitHandler (item) {
      this.$emit('submit', item)
      load()
    }

    function formatDate (date, isDate) {
      if (isDate) {
        return date.substring(0, 10)
      }
    }

    return {
      availableRides,
      options,
      totalCount,
      service,
      headers,
      selected,
      submitButtonLabel,
      isDriver,
      showAllRides,
      submitHandler,
      loading,
      load,
      showFilters,
      churchNameFilter,
      churchDenominationFilter,
      pickupLocationOptions,
      pickupLocationFilter,
      showDetailButton,
      showDetailsDialog,
      showEditDialog,
      isFiltered,
      denominationOptions,
      editRide,
      formRules: [
        v => !!v || 'Field is required'
      ],
      formatDate
    }
  },
  filters: {
    stringFormatDate
  }
}
</script>
